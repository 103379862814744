<template>
  <div class="container-page wide" style="max-width: 1406px">
    <div class="registry-mpk">
      <div class="registry-mpk__table-container js-table-arrow">
        <div class="registry-mpk__table table-container-base table-container-base--no-borders" v-if="page">
          <table>
            <thead>
              <tr>
                <th class="table-container-base__th column-68" v-if="canEdit"></th>
                <th class="table-container-base__th column-50 padding-left-md-14">
                  <div class="checkboxInput checkboxInput--no-label">
                    <input type="checkbox" v-model="selectAllValue" id="allChkIsActive" :value="true" />
                    <label for="allChkIsActive" class="form-label"></label>
                  </div>
                </th>
                <th
                  @click="changeSorting(0)"
                  :class="getClassSorting(0)"
                  class="table-container-base__th column-200 cursor-pointer"
                >
                  Заседание МПК
                </th>
                <!--Название поручения-->
                <th
                  @click="changeSorting(1)"
                  :class="getClassSorting(1)"
                  class="table-container-base__th column-160 cursor-pointer"
                >
                  № Поручения по итогам заседания МПК
                </th>
                <th
                  @click="changeSorting(2)"
                  :class="getClassSorting(2)"
                  class="table-container-base__th column-68 cursor-pointer"
                >
                  № п/п
                </th>
                <th
                  @click="changeSorting(3)"
                  :class="getClassSorting(3)"
                  class="table-container-base__th column-200 cursor-pointer"
                >
                  Поручение
                </th>
                <th
                  @click="changeSorting(4)"
                  :class="getClassSorting(4)"
                  class="table-container-base__th column-180 cursor-pointer"
                >
                  Срок исполнения
                </th>
                <th
                  @click="changeSorting(5)"
                  :class="getClassSorting(5)"
                  class="table-container-base__th column-140 cursor-pointer"
                >
                  Исполнитель
                </th>
                <th
                  @click="changeSorting(6)"
                  :class="getClassSorting(6)"
                  class="table-container-base__th column-160 cursor-pointer"
                >
                  Статус
                </th>
                <th
                  @click="changeSorting(7)"
                  :class="getClassSorting(7)"
                  class="table-container-base__th column-100 cursor-pointer"
                >
                  Отчет
                </th>
              </tr>
              <tr class="table-container-base__filters">
                <th v-if="canEdit" class="padding-left-md-14"></th>
                <th></th>
                <th class="filter">
                  <SelectSingleComponentCheckbox
                    v-model="filterValue.mpkEventId"
                    mod="filter-small"
                    placeholder="Выберите"
                    trackBy="text"
                    valueProp="id"
                    labelOptions="text"
                    :options="mpkEventList"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    modClass="small"
                    clearButtonModClass="small"
                    placeholder="Введите"
                    v-model="filterValue.razdelProtocal"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    modClass="small"
                    clearButtonModClass="small"
                    placeholder="№"
                    v-model="filterValue.noPoint"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    modClass="small"
                    clearButtonModClass="small"
                    placeholder="Введите"
                    v-model="filterValue.brief"
                  />
                </th>
                <th class="filter filter--half horizontally-date">
                  <FilterInput
                    class="filter__item"
                    modClass="filter-small"
                    type="date"
                    v-model="filterValue.srokFrom"
                  />
                  <FilterInput class="filter__item" modClass="filter-small" type="date" v-model="filterValue.srokTo" />
                </th>
                <th class="filter">
                  <SelectSingleComponentCheckbox
                    v-model="filterValue.organizationId"
                    mod="filter-small"
                    placeholder="Выберите"
                    trackBy="text"
                    valueProp="id"
                    labelOptions="text"
                    :options="organizationList"
                  />
                </th>

                <th class="filter">
                  <SelectSingleComponentCheckbox
                    v-model="filterValue.mpkRegistryStatusId"
                    mod="filter-small"
                    placeholder="Выберите"
                    trackBy="text"
                    valueProp="id"
                    labelOptions="text"
                    :options="statusList"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    modClass="small"
                    clearButtonModClass="small"
                    placeholder="Все"
                    v-model="filterValue.report"
                  />
                </th>
              </tr>
            </thead>
            <tbody v-if="page.items && page.items?.length && !isLoading">
              <tr v-for="item in page.items" :key="item.id" @dblclick="viewCard(item.assignmentId)">
                <td class="column-68 padding-left-md-14" v-if="canEdit">
                  <div class="wrap-icon">
                    <IconComponent class="icon--full-svg" @click="editCard(item.assignmentId)" name="edit" />
                    <IconComponent class="icon--full-svg delete" @click="deleteCard(item.id)" name="delete-not-fill" />
                  </div>
                </td>
                <td class="column-50">
                  <div class="checkboxInput checkboxInput--no-label">
                    <input type="checkbox" v-model="selectedIdsValue" :id="item.id" :value="item.id" />
                    <label :for="item.id" class="form-label"></label>
                  </div>
                </td>
                <td class="column-160">{{ item.eventName }}</td>
                <td class="column-160">{{ item.assignmentRazdelProtocal }}</td>
                <!--Номер-->
                <td class="column-68">
                  {{ item.assignmentNoPoint }}
                </td>
                <td class="column-200">{{ item.assignmentBrief }}</td>
                <td class="column-160">
                  {{ $momentFormat(item.assignmentSrok, 'DD.MM.YYYY') }}
                </td>
                <td class="column-140">{{ item.organizationName }}</td>
                <td class="column-160">
                  <div class="d-flex status-container">
                    <span>{{ item.statusName }}</span>
                  </div>
                </td>
                <td class="column-100">
                  <p v-snip="3">{{ item.reportTotal }}</p>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="isLoading">
              <tr>
                <td class="text-center" :colspan="canEdit ? 10 : 9"><Loader class="loader"></Loader></td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" :colspan="canEdit ? 10 : 9">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <template v-if="page.pagesTotal > 1">
          <PaginationRedesigned
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            :loadingMore="isLoadingMore"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import FilterInput from '@/common/components/FilterInput';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import Loader from '@/components/Loader';

  export default {
    components: {
      FilterInput,
      IconComponent,
      PaginationRedesigned,
      Loader,
      SelectSingleComponentCheckbox,
    },
    emits: ['update:selectAll', 'update:selectedIds', 'changeSorting', 'editCard', 'deleteCard', 'onPageChange'],
    props: {
      canEdit: {
        type: Boolean,
        required: true,
      },
      selectAll: {
        type: Boolean,
        required: true,
      },
      filter: {
        type: Object,
        required: true,
      },
      page: {
        page: Object,
        required: true,
      },
      mpkEventList: {
        type: Array,
        required: true,
      },
      organizationList: {
        type: Array,
        required: true,
      },
      statusList: {
        type: Array,
        required: true,
      },
      selectedIds: {
        type: Array,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
      isLoadingMore: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      changeSorting(id) {
        this.$emit('changeSorting', id);
      },
      getClassSorting(id) {
        if (this.filterValue.sortColumn == id) {
          if (this.filterValue.sortOrder == 0) return 'table-header_descending';
          else if (this.filterValue.sortOrder == 1) return 'table-header_ascending';
        }
        return '';
      },
      editCard(assignmentId) {
        this.$emit('editCard', assignmentId);
      },
      deleteCard(assignmentId) {
        this.$emit('deleteCard', assignmentId);
      },
      viewCard(id) {
        this.$router.push({
          name: 'AssignmentCard',
          params: { id: id, action: 'edit' },
          state: { location: { countryId: this.filter.countryId, mpkEventId: this.filter.mpkEventId } },
        });
      },
      onPageChange(pageNumber, addToExist) {
        this.$emit('onPageChange', pageNumber, addToExist);
      },
    },

    computed: {
      selectAllValue: {
        get() {
          return this.selectAll;
        },
        set(value) {
          this.$emit('update:selectAll', value);
        },
      },
      selectedIdsValue: {
        get() {
          return this.selectedIds;
        },
        set(value) {
          this.$emit('update:selectedIds', value);
        },
      },
      filterValue: {
        get() {
          return this.filter;
        },
        set(value) {
          this.$emit('update:filter', value);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';
  .registry-mpk {
    &__table {
      margin-bottom: 12px;
    }
  }

  .filter {
    &--half {
      display: flex;

      .filter__item {
        margin-right: 8px;
        height: 32px;
        max-width: 76px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .table-header_ascending::after {
    position: absolute;
    content: '';
    right: 8px;
    top: 4px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid #0098b7;
  }

  .table-header_descending::after {
    position: absolute;
    content: '';
    right: 8px;
    top: 4px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid #0098b7;
  }
</style>
